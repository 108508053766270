.wrap {
    background-color: #d8d8d8;

    // 页头
    .page-header {
        background-color: #fff;

        // 菜单栏
        .menubar {
            height: 80px;
            width: @app-width;
            margin: 0 auto;
            // logo
            background-image: url(../../../assets/images/logo-2.png);
            background-position: left center;
            background-repeat: no-repeat;
            background-size: auto 50%;

            .menubar-list {
                height: 80px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .menubar-list__item {
                    min-width: 80px;
                    text-align: center;

                    &:last-child {
                        margin-left: 15px;
                    }
                }
            }
        }

        // 导航栏
        .navbar {
            background-color: @primary-color;
            overflow: hidden;

            .navbar-list {
                height: 55px;
                width: @app-width;
                margin: 0 auto;

                .navbar-list__item {
                    float: left;
                    min-width: 120px;
                    line-height: 55px;
                    color: #fff;
                    text-align: center;
                    cursor: pointer;
                    font-size: 14px;

                    &:hover, &.active {
                        background-color: @active-bg-color;
                    }
                }
            }
        }
    }

    // 页脚
    .page-footer {
        line-height: 60px;
        color: #fff;
        background-color: @primary-color;
        text-align: center;
    }

    // 版心
    >.container {
        min-height: calc(100vh - 135px - 60px);
        width: @app-width;
        margin: 0 auto;
    }
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;