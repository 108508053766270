.notice {
    background-color: #fff;
    padding: 20px 40px;

    .notice-list {
        border: 1px solid #e4e4e4;
        border-radius: 3px;

        .notice-list-head {
            height: 40px;
            background-color: #edf1f2;
            border-bottom: 1px solid #e4e4e4;
        }

        .notice-list-body {
            padding: 20px;

            .notice-item {
                display: flex;
                line-height: 50px;
                cursor: pointer;

                .cover {
                    width: 180px;
                    height: 110px;
                    margin-right: 20px;
                    align-self: center;
                }

                .content {
                    flex: 1;
                    overflow: hidden;

                    .title {
                        font-weight: bold;
                    }
                }

                .date {
                    width: 150px;
                    text-align: right;
                }

                &:hover {
                    color: #e7bc71;
                }

                // 图文样式
                &.thumb {
                    line-height: normal;
                    padding: 10px;
                    
                    .content {
                        flex: 1;
                        overflow: hidden;

                        .title {
                            font-weight: normal;
                        }

                        .summary {
                            padding-top: 10px;
                            font-size: 12px;
                            color: #606266;
                        }
                    }

                    .date {
                        align-self: center;
                    }

                    &:hover {
                        color: inherit;
                        background-color: #FAFAFA;
                    }
                }
            }
        }

        .notice-list-foot {
            padding: 5px 0;
            text-align: center;
        }
    }
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;