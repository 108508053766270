// 超出省略
.h-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}
.h-ellipsis-1 { // 超出1行省略
    .h-ellipsis();
    white-space: nowrap;
    word-break: break-all;
}
.h-ellipsis-2 { // 超出2行省略
    .h-ellipsis();
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}