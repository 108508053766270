[data-component-name="notice-modal"] {
    .ant-modal {
        max-width: 100vw;
        width: 1100px !important;

        &.fullscreen {
            width: 100% !important;

            .ant-modal-content {
                height: 100vh;
            }
        }

        @modal-header-height: 54px;
        .ant-modal-header {
            border-bottom: none;
            height: @modal-header-height;
        }
    
        .ant-modal-body {
            height: calc(100% - @modal-header-height);
            padding: 0;
        }
    }

    // 全屏按钮
    span.fullscreen-btn {
        position: absolute;
        top: 0;
        right: 56px;
        width: 56px;
        line-height: 56px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.45);
        cursor: pointer;

        &:hover {
            color: #404040;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        min-height: 60vh;
        margin-bottom: -5px;
    }
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;