@import '../assets/css/iconfont/iconfont.css';
.app ul {
  padding: 0;
  margin: 0;
}
.app ul > li {
  list-style: none;
}
.app h1,
.app h2,
.app h3,
.app h4,
.app h5,
.app h6 {
  margin: 0;
}
.app p {
  margin: 0;
}
.h-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.h-ellipsis-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
}
.h-ellipsis-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Microsoft YaHei", "微软雅黑", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app {
  min-width: 1400px;
}
