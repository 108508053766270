@list-item-line-height: 37px;

.home {
    padding: 20px 0;

    .section-container {
        margin-bottom: 25px;
        background-color: #fff;
        border-top: 2px @primary-color solid;
        box-shadow: 0 3px 6px -0 rgba(0, 0, 0, .09);

        .section-header {
            border-bottom: 2px #f4f4f4 solid;

            .title {
                width: 180px;
                padding-left: 30px;
                font-weight: bold;
                font-size: 16px;
                color: @primary-color;
            }

            .more {
                padding: 0 10px;
                font-weight: bold;
            }
        }

        // 公告版块
        &[data-component-name="notice"] {
            .section-header {
                display: flex;
                justify-content: space-between;
                padding-top: 8px;
                line-height: 35px;
            }

            .section-body {
                min-height: 250px;
                padding: 15px;

                .list {
                    .item {
                        display: flex;
                        line-height: @list-item-line-height;
                        cursor: pointer;

                        .title {
                            flex: 1;
                            padding: 0 15px;
                        }

                        &:hover {
                            color: @active-color;
                        }
                    }
                }
            }
        }

        // 新闻版块
        &[data-component-name="news"] {
            .section-header {
                display: flex;
                justify-content: space-between;
                padding-top: 8px;
                line-height: 35px;
            }

            .section-body {
                min-height: 250px;
                display: flex;
                padding: 25px 15px;

                .img {
                    width: 40%;
                    padding-right: 30px;
                    cursor: pointer;
                    
                    img.ant-image-img {
                        width: 100%;
                        max-height: calc(10 * @list-item-line-height);
                    }
                }

                .list {
                    flex: 1;
                    overflow: hidden;

                    .item {
                        display: flex;
                        line-height: @list-item-line-height;
                        cursor: pointer;
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 8px;
                            height: 8px;
                            border-radius: 4px;
                            background-color: @active-color;
                        }

                        .title {
                            flex: 1;
                            padding: 0 15px;
                        }

                        &:hover {
                            color: @active-color;
                        }
                    }
                }
            }
        }
    }
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;