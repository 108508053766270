.workbench {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    // 左侧区域
    .left-area {
        width: 150px;
        margin-right: 20px;

        // 导航菜单
        >.sidebar-menu {
            height: 100%;
            background-color: #fff;
            
            .menu-item {
                line-height: 40px;
                font-weight: bold;
                font-size: 14px;
                color: #606266;
                cursor: pointer;
                text-align: center;
                position: relative;

                &:hover,
                &.active {
                    color: #da0f2b;
                }

                .badge {
                    position: absolute;
                    font-weight: normal;
                    color: #da0f2b;
                    margin-left: 5px;
                }
            }
        }
    }

    // 右侧区域
    .right-area {
        flex: 1;
        height: 100%;

        >.container {
            background-color: #fff;
            min-height: 80vh;

            form.ant-form {
                .ant-form-item {
                    margin-bottom: 10px;
                }
            }

            // 发起流程
            &[data-component-name='MyWorkflow'] {
                background-color: transparent;

                .top-bar {
                    padding: 10px 20px;
                    background-color: #fff;
                    border-bottom: 1px solid #f0f0f0;
                    display: flex;
                    align-items: center;

                    .title {
                        font-size: 16px;
                        font-weight: bold;
                        color: #606266
                    }

                    .extra {
                        flex: 1;
                    }
                }

                .row {
                    background-color: #fff;

                    &:not(:last-child) {
                        // 除最后一行，其余都加外间距
                        margin-bottom: 20px;
                    }

                    >.row-head {
                        line-height: 45px;
                        padding: 0 20px;

                        .title {
                            font-weight: bold;
                            color: #606266
                        }
                    }

                    >.row-body {
                        padding: 15px;

                        .workflow-item {
                            .icon {
                                width: 30px;
                                height: 30px;
                                margin-bottom: 5px;
                            }

                            .text {
                                line-height: 20px;
                                font-size: 12px;
                                margin-bottom: 5px;
                                color: #606266;
                            }
                        }
                    }
                }
            }
        }
    }
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;