.system {
    padding: 20px 0;

    .collection {
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 15px;

        .item {
            background-color: #fff;
            margin-bottom: 15px;
            transition: all 0.5s;
            cursor: pointer;
            position: relative;

            .item-container {
                padding: 90px 0;

                .info {
                    text-align: center;

                    .logo {
                        height: 100px;
                    }

                    .name {
                        font-size: 34px;
                    }
                }

                .badge {
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;

                    .ant-badge {
                        font-size: 18px;

                        &:not(:last-child) {
                            margin-right: 60px;
                        }

                        .ant-badge-count {
                            padding: 0;
                            width: 31px;
                            height: 13px;
                            line-height: 13px;
                        }
                    }
                }
            }

            .item-mask {
                visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(0, 0, 0, .5);
                display: flex;
                justify-content: end;
                align-items: center;

                >i {
                    font-size: 100px;
                    color: #b3b3b3;
                    animation: arrow_move 1s infinite linear;
                }
            }

            &:hover {
                .item-mask {
                    visibility: visible;
                }
            }
        }
    }
}

// 箭头动画
@keyframes arrow_move {
    from {
        transform: translateX(-20px);
    }

    to {
        transform: translateX(10px);
    }
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;