.login {
    height: 100vh;
    display: flex;
    flex-direction: column;

    // 背景层
    [class^="layer-"] {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .layer-top {
        height: 24%;
        background-image: url(./../../../assets/images/bg-1.jpg);

        .logo {
            height: 30%;
            margin: 30px 0 0 1.5625vw;
        }
    }

    .layer-middle {
        flex: 1;
        background-image: url(./../../../assets/images/bg-2.jpg);
        background-position: -3%;
    }

    .layer-bottom {
        z-index: 2;
        height: 15%;
        background-image: url(./../../../assets/images/bg-3.jpg);
    }

    // 登录面板
    .login-panel {
        position: fixed;
        z-index: 1;
        right: 0;
        width: 30vw;
        min-width: 15.625vw;
        height: 100vh;
        background-color: #fff;
        padding-bottom: 15%;

        // 登录表单
        .ant-form {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            padding: 0 5vw;
            transform: translate(-50%, -50%);

            i[class^="fm-icon"] {
                color: #dcdcdc;
                font-size: 1.5625vw;
            }

            // 输入框
            .ant-form-item:nth-child(-n + 2) {
                margin-bottom: 5vh;

                .ant-form-item-control-input-content {
                    border-bottom: 0.0521vw #dcdcdc solid;

                    .ant-input-affix-wrapper {
                        padding: 0.2083vw 0;

                        input.ant-input {
                            font-size: 1.25vw;
                            margin-left: 0.7813vw;
                        }
                    }
                }
            }

            // 登录按钮
            .ant-form-item:nth-child(4) {
                margin: 0 0.7813vw 2.0833vw;

                button[type="submit"] {
                    border-radius: 0.2604vw;
                    height: 2.8646vw;
                    font-size: 1.25vw;
                }
            }
        }
    }
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;