@font-face {
  font-family: "iconfont";
  src: url('iconfont.woff2?t=1655084913684') format('woff2'),
       url('iconfont.woff?t=1655084913684') format('woff'),
       url('iconfont.ttf?t=1655084913684') format('truetype');
}

[class^='fm-icon'] {
  font-family: 'iconfont' !important;
  font-size: 0.8333vw;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fm-icon-user::before {
  content: '\e612';
}

.fm-icon-lock::before {
  content: '\e617';
}

.fm-icon-search::before {
  content: '\e632';
}

.fm-icon-maximize::before {
  content: '\e786';
}

.fm-icon-minimize::before {
  content: '\e608';
}

.fm-icon-folder-open::before {
  content: '\eac5';
}

.fm-icon-folder-close::before {
  content: '\eac4';
}

.fm-icon-file::before {
  content: '\e637';
}

.fm-icon-arrow-right::before {
  content: '\e662';
}

.fm-icon-chart::before {
  content: '\e922';
}

.fm-icon-horn::before {
  content: '\e621';
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;