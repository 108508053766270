.file {
    flex: 1;
    background-color: #fff;
    padding: 20px 0;

    .file-head {
        line-height: 40px;
        padding: 0 20px;
        color: @primary-color;
        border-bottom: 1px solid #f4f4f4;
        font-size: 16px;
    }

    .file-body {
        padding: 20px;

        .ant-tree.ant-tree-directory .ant-tree-treenode-selected {
            &::before {
                background-color: #f5f7fa;
            }

            .ant-tree-node-content-wrapper {
                color: #000000D9;
            }
        }
    }
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;