.report {
    background-color: #fff;
    padding: 20px;

    .ant-row {
        .ant-col {
            margin-bottom: 24px;

            .card {
                border: 1px solid #f0f0f0;
                height: 100%;

                .card-header {
                    padding: 10px 24px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #f0f0f0;
                    background-color: @primary-color;
                    color: #fff;

                    .icon {
                        font-size: 20px;
                        margin-right: 5px;
                    }

                    .title {
                        font-size: 16px;
                    }
                }

                .card-body {
                    padding: 24px;

                    .list__cell {
                        display: block;
                        color: #606266;
                        line-height: 30px;
                        cursor: pointer;
    
                        &:hover {
                            color: @active-color;
                        }

                        &.disabled {
                            color: #ccc
                        }
                    }
                }
            }
        }
    }
}
@primary-color: #c51b1e;@font-size-base: 12px;@text-color: #303133;@text-color-secondary: #606266;@app-width: 1400px;@active-color: #e84013;@active-bg-color: #b40d25;